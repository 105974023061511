
.main-page-container {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

.forecast-area-row {
 height: calc(100% - 52px);
}