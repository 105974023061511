.forecast-chart-container {
  width: 95%;
  height: 100%;
  display: flex;
  justify-content: start;
  flex-direction: column;
}

.forecast-chart-row {
  max-height: calc(100% - 30px);
  aspect-ratio: 4 / 3;
}