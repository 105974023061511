.disclaimer-notice {
    font-size: 0.7rem;
}

.disclaimer-linebreak {
    display: none;
}

@media only screen and (max-width: 735px) {
  .disclaimer-notice {
    font-size: 0.5rem;
  }

  .disclaimer-linebreak {
    display: block;
  }
}